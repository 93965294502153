// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gilory Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Gilory Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.containerCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
}


.cardHome {
    margin: 10px;
    border: none;
}
.cardImgHome {
    width: 285px; 
    height: 285px;
    background-color: #fd5000;
}

.cardTextHome {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight: bold;
}


.cardBodyHome {
    background-color: #fd5000 ;
    border: none;
}



@media (max-width: 1000px) {
    .cardImgHome {
        width: 100%; 
        height: auto; 
    } 
    .cardHome {
        margin: 10px;
    }
    .cardTextHome {
        font-size: 8px;
    }
}
@media (max-width: 500px) {
    
    .cardHome {
        margin: 5px;
    }
    .containerCards {
        width: 100%;
    }
    .cardTextHome {
        font-size: 5px;
    }
}



.carousel-control-next, .carousel-control-prev{
    width: 6%!important;
}

#arrowsSliders{
    background-color: white!important;
    border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/cardHome.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,4CAAqC;AACzC;;AAEA;IACI,0BAA0B;IAC1B,4CAAmC;AACvC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;AACzB;;;AAGA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,kCAAkC;IAClC,YAAY;IACZ,iBAAiB;AACrB;;;AAGA;IACI,0BAA0B;IAC1B,YAAY;AAChB;;;;AAIA;IACI;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,YAAY;IAChB;IACA;QACI,cAAc;IAClB;AACJ;AACA;;IAEI;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;IACA;QACI,cAAc;IAClB;AACJ;;;;AAIA;IACI,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Gilory Medium';\n    src: url('./fonts/Gilroy-Medium.ttf');\n}\n\n@font-face {\n    font-family: 'Gilory Bold';\n    src: url('./fonts/Gilroy-Bold.ttf');\n}\n\n.containerCards {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    align-content: center;\n}\n\n\n.cardHome {\n    margin: 10px;\n    border: none;\n}\n.cardImgHome {\n    width: 285px; \n    height: 285px;\n    background-color: #fd5000;\n}\n\n.cardTextHome {\n    text-align: center;\n    font-family: 'Poppins', sans-serif;\n    color: black;\n    font-weight: bold;\n}\n\n\n.cardBodyHome {\n    background-color: #fd5000 ;\n    border: none;\n}\n\n\n\n@media (max-width: 1000px) {\n    .cardImgHome {\n        width: 100%; \n        height: auto; \n    } \n    .cardHome {\n        margin: 10px;\n    }\n    .cardTextHome {\n        font-size: 8px;\n    }\n}\n@media (max-width: 500px) {\n    \n    .cardHome {\n        margin: 5px;\n    }\n    .containerCards {\n        width: 100%;\n    }\n    .cardTextHome {\n        font-size: 5px;\n    }\n}\n\n\n\n.carousel-control-next, .carousel-control-prev{\n    width: 6%!important;\n}\n\n#arrowsSliders{\n    background-color: white!important;\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
