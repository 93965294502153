// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Gilory Medium;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-family: Gilory Bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.card-body {
    font-family: 'Poppins', sans-serif; 
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/sliders/cards5.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4CAAmC;IACnC,wBAAwB;IACxB,4CAAiC;AACrC;;AAEA;IACI,kCAAkC;IAClC,YAAY;AAChB","sourcesContent":["@font-face {\n    font-family: Gilory Medium;\n    src: url(./fonts/Gilroy-Medium.ttf);\n    font-family: Gilory Bold;\n    src: url(./fonts/Gilroy-Bold.ttf);\n}\n\n.card-body {\n    font-family: 'Poppins', sans-serif; \n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
