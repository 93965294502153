// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Gilory Medium;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-family: Gilory Bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center; 
  }
.myButton {
    display: inline-block;
    padding: 10px 20px; 
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #fd5000; 
    border-radius: 20px; 
    cursor: pointer;
    
}

.carouselTexTitle {
    font-family: Gilory Bold;
    color: white;
    font-size: 100px;
    
}
.carouselText{
    font-family: Gilory Medium;
    color: white;
    font-size: 20px;
    text-align: center;
    justify-content: center;
}

.carousel-text {
 margin-bottom: 400px;
}
.carousel-item img {
  width: 100%; 
  height: 100%; 
  object-fit: cover;
}


  @media (max-width: 800px) {
    .carousel-inner .carousel-item .carousel-text {
      display: block !important; 
    }
    .carouselTexTitle{
        font-size: 30px;
    }
    .carouselText{
        font-size: 15px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/Home/carousel/Carousel.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4CAAmC;IACnC,wBAAwB;IACxB,4CAAiC;AACrC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;AACF;IACI,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;;AAEnB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;;AAEpB;AACA;IACI,0BAA0B;IAC1B,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;CACC,oBAAoB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;;EAGE;IACE;MACE,yBAAyB;IAC3B;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;EACF","sourcesContent":["@font-face {\n    font-family: Gilory Medium;\n    src: url(./fonts/Gilroy-Medium.ttf);\n    font-family: Gilory Bold;\n    src: url(./fonts/Gilroy-Bold.ttf);\n}\n\n.carousel-item {\n    display: flex;\n    justify-content: center;\n    align-items: center; \n  }\n.myButton {\n    display: inline-block;\n    padding: 10px 20px; \n    text-align: center;\n    text-decoration: none;\n    color: white;\n    background-color: #fd5000; \n    border-radius: 20px; \n    cursor: pointer;\n    \n}\n\n.carouselTexTitle {\n    font-family: Gilory Bold;\n    color: white;\n    font-size: 100px;\n    \n}\n.carouselText{\n    font-family: Gilory Medium;\n    color: white;\n    font-size: 20px;\n    text-align: center;\n    justify-content: center;\n}\n\n.carousel-text {\n margin-bottom: 400px;\n}\n.carousel-item img {\n  width: 100%; \n  height: 100%; \n  object-fit: cover;\n}\n\n\n  @media (max-width: 800px) {\n    .carousel-inner .carousel-item .carousel-text {\n      display: block !important; \n    }\n    .carouselTexTitle{\n        font-size: 30px;\n    }\n    .carouselText{\n        font-size: 15px;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
