// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featuredProductContainer {
    padding: 20px;
    margin: 20px;
    margin-left: 10%;
    margin-right: 5%;
    max-width: 600px; 
    overflow: hidden; 
    }
  
  .featuredProductText {
    font-family: 'Poppins', sans-serif; 
    color: gray;
    margin-top: 20px;
  }
  
  .featuredProductTitle {
    font-family: Gilory Bold; 
    color: black;
    font-size: 40px;
  }
  
  .featuredProductButton {
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px; 
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #fd5000;
    border-radius: 20px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/cardHome/cardHomeNews.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB;;EAEF;IACE,kCAAkC;IAClC,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,wBAAwB;IACxB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".featuredProductContainer {\n    padding: 20px;\n    margin: 20px;\n    margin-left: 10%;\n    margin-right: 5%;\n    max-width: 600px; \n    overflow: hidden; \n    }\n  \n  .featuredProductText {\n    font-family: 'Poppins', sans-serif; \n    color: gray;\n    margin-top: 20px;\n  }\n  \n  .featuredProductTitle {\n    font-family: Gilory Bold; \n    color: black;\n    font-size: 40px;\n  }\n  \n  .featuredProductButton {\n    margin-top: 20px;\n    display: inline-block;\n    padding: 10px 20px; \n    text-align: center;\n    text-decoration: none;\n    color: white;\n    background-color: #fd5000;\n    border-radius: 20px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
