// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Gilory Medium;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-family: Gilory Bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}



.calculatorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .calculatorItem {
    margin-bottom: 10px; /* Ajusta el espacio entre el ícono y el input */
    font-size: 60px;
    color: #fd5000;
  }
  
  .calculatorInput {
    width: 400px; 
    height: 50px; 
    text-align: center; 
    margin-bottom: 10px; 
    border: 1px solid #ccc; 
    border-radius: 20px; 
    font-size: 20px; 
  }
  
  .calculatorUnit {
    display: inline-block;
    margin-left: 10px; 
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
  }

  @media (max-width: 800px) {
   
    .calculatorInput {
      width: 150px; 
      height: 50px; 
      text-align: center; 
      margin-bottom: 10px; 
      border: 1px solid #ccc; 
      border-radius: 20px; 
      font-size: 20px; 
    }


}`, "",{"version":3,"sources":["webpack://./src/components/calculator/calculator.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4CAAmC;IACnC,wBAAwB;IACxB,4CAAiC;AACrC;;;;AAIA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB,EAAE,gDAAgD;IACrE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,kCAAkC;IAClC,eAAe;EACjB;;EAEA;;IAEE;MACE,YAAY;MACZ,YAAY;MACZ,kBAAkB;MAClB,mBAAmB;MACnB,sBAAsB;MACtB,mBAAmB;MACnB,eAAe;IACjB;;;AAGJ","sourcesContent":["@font-face {\n    font-family: Gilory Medium;\n    src: url(./fonts/Gilroy-Medium.ttf);\n    font-family: Gilory Bold;\n    src: url(./fonts/Gilroy-Bold.ttf);\n}\n\n\n\n.calculatorContainer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .calculatorItem {\n    margin-bottom: 10px; /* Ajusta el espacio entre el ícono y el input */\n    font-size: 60px;\n    color: #fd5000;\n  }\n  \n  .calculatorInput {\n    width: 400px; \n    height: 50px; \n    text-align: center; \n    margin-bottom: 10px; \n    border: 1px solid #ccc; \n    border-radius: 20px; \n    font-size: 20px; \n  }\n  \n  .calculatorUnit {\n    display: inline-block;\n    margin-left: 10px; \n    margin-bottom: 10px;\n    font-family: 'Poppins', sans-serif;\n    font-size: 40px;\n  }\n\n  @media (max-width: 800px) {\n   \n    .calculatorInput {\n      width: 150px; \n      height: 50px; \n      text-align: center; \n      margin-bottom: 10px; \n      border: 1px solid #ccc; \n      border-radius: 20px; \n      font-size: 20px; \n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
