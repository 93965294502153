// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gilory Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Gilory Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}


.containerCards2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}
.cardHome2 {
    margin: 20px;
    width: calc(30% - 40px);
}
.cardText2 {
    font-size: 10px;
    font-family: 'Gilory Medium';
    color: gray;
}
.cardImgHome2 {
    width: 285px; 
    height: 285px;
}
.cardTextTitle2 {
    font-size: 15px;
    font-family: 'Gilory Bold';
    color: #fd5000; 
}
@media (max-width: 1000px) {
    .containerCards2 {
       
    }
    
    .cardImgHome2 {
        width: 100%; 
        height: auto; 
    }
}
@media (max-width: 800px) {
    
    .cardImgHome2 {
        width: 100%; 
        height: auto; 
    }
}

@media (max-width: 500px) {

    
    .cardTextTitle2 {
        font-size: 5px;
        font-family: 'Gilory Bold';
        color: #fd5000; 
    }
    
}

`, "",{"version":3,"sources":["webpack://./src/components/Card/cardHome2.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,4CAAqC;AACzC;;AAEA;IACI,0BAA0B;IAC1B,4CAAmC;AACvC;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;;AAE3B;AACA;IACI,YAAY;IACZ,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,4BAA4B;IAC5B,WAAW;AACf;AACA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B,cAAc;AAClB;AACA;IACI;;IAEA;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ;AACA;;IAEI;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;;;IAGI;QACI,cAAc;QACd,0BAA0B;QAC1B,cAAc;IAClB;;AAEJ","sourcesContent":["@font-face {\n    font-family: 'Gilory Medium';\n    src: url('./fonts/Gilroy-Medium.ttf');\n}\n\n@font-face {\n    font-family: 'Gilory Bold';\n    src: url('./fonts/Gilroy-Bold.ttf');\n}\n\n\n.containerCards2 {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    \n}\n.cardHome2 {\n    margin: 20px;\n    width: calc(30% - 40px);\n}\n.cardText2 {\n    font-size: 10px;\n    font-family: 'Gilory Medium';\n    color: gray;\n}\n.cardImgHome2 {\n    width: 285px; \n    height: 285px;\n}\n.cardTextTitle2 {\n    font-size: 15px;\n    font-family: 'Gilory Bold';\n    color: #fd5000; \n}\n@media (max-width: 1000px) {\n    .containerCards2 {\n       \n    }\n    \n    .cardImgHome2 {\n        width: 100%; \n        height: auto; \n    }\n}\n@media (max-width: 800px) {\n    \n    .cardImgHome2 {\n        width: 100%; \n        height: auto; \n    }\n}\n\n@media (max-width: 500px) {\n\n    \n    .cardTextTitle2 {\n        font-size: 5px;\n        font-family: 'Gilory Bold';\n        color: #fd5000; \n    }\n    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
