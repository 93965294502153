// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assest/bannercontact1.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gilory Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Gilory Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.conteinerContact {
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    margin-top: 100px;
}

.conteinerContactImg {
    width: auto;
    height: 500px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.ContactTitle {
    font-family: 'Gilory Bold';
    color: white;
}
.conteinerCon{
    background-color: #cccc;
}
.conteinerContactForm {
    display: flex;
    background-color: white;
    height: 100%;
    justify-content: center;
    border-radius: 20px;
    margin: 5%;
    

}
.conteinerForm {
    width: 100%;
    margin: 20px;
    border-radius: 10px;
    background-color: white;
    
}

@media (max-width: 800px) {
    /*Banner*/
    /*Slioder*/

    /*Footer*/


}
`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,4CAAmC;AACvC;;AAEA;IACI,0BAA0B;IAC1B,4CAAiC;AACrC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yDAAoD;IACpD,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;;;AAGd;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,SAAS;IACT,UAAU;;IAEV,SAAS;;;AAGb","sourcesContent":["@font-face {\n    font-family: 'Gilory Medium';\n    src: url(./fonts/Gilroy-Medium.ttf);\n}\n\n@font-face {\n    font-family: 'Gilory Bold';\n    src: url(./fonts/Gilroy-Bold.ttf);\n}\n\n.conteinerContact {\n    display: flex;\n    flex-direction: column;\n    height: auto;\n    width: auto;\n    margin-top: 100px;\n}\n\n.conteinerContactImg {\n    width: auto;\n    height: 500px;\n    background-image: url('./assest/bannercontact1.jpg');\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n\n.ContactTitle {\n    font-family: 'Gilory Bold';\n    color: white;\n}\n.conteinerCon{\n    background-color: #cccc;\n}\n.conteinerContactForm {\n    display: flex;\n    background-color: white;\n    height: 100%;\n    justify-content: center;\n    border-radius: 20px;\n    margin: 5%;\n    \n\n}\n.conteinerForm {\n    width: 100%;\n    margin: 20px;\n    border-radius: 10px;\n    background-color: white;\n    \n}\n\n@media (max-width: 800px) {\n    /*Banner*/\n    /*Slioder*/\n\n    /*Footer*/\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
