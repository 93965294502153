// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caruselImgenHome {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 500px) {
    .caruselcontHome {
        
        width: 150%;
    }
}

#review{
    margin-left: -3px;
}

.titleReview{
    font-size: 1.5rem!important;
    color: white!important;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/carousel/carousel1.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;;AAGA;IACI;;QAEI,WAAW;IACf;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".caruselImgenHome {\n    max-width: 100%;\n    height: auto;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n\n@media (max-width: 500px) {\n    .caruselcontHome {\n        \n        width: 150%;\n    }\n}\n\n#review{\n    margin-left: -3px;\n}\n\n.titleReview{\n    font-size: 1.5rem!important;\n    color: white!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
