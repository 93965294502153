// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardProductBody{
    display: flex;
    flex-direction: row;
    width: 600px;
}
.cardSliderProduct{
    width: 600px;
    height: 600px;
    max-Width: 100vh;

}



@media (max-width: 500px) {
    .cardSliderProduct {
        width: 400px;
        height: 400px;
        max-Width: 50vh;
    }
    .cardProductBody{
        width: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Card/cardProducts/CardProduct1.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;;AAEpB;;;;AAIA;IACI;QACI,YAAY;QACZ,aAAa;QACb,eAAe;IACnB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".cardProductBody{\n    display: flex;\n    flex-direction: row;\n    width: 600px;\n}\n.cardSliderProduct{\n    width: 600px;\n    height: 600px;\n    max-Width: 100vh;\n\n}\n\n\n\n@media (max-width: 500px) {\n    .cardSliderProduct {\n        width: 400px;\n        height: 400px;\n        max-Width: 50vh;\n    }\n    .cardProductBody{\n        width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
